import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import styled, { ThemeProvider } from "styled-components";
import { Reset } from "styled-reset";

import { getPageTitle } from "../../Locales";
import { theme } from "../../theme/theme";
import FontFaces from "../../theme/fonts/font-faces";
import GlobalStyle from "../../theme/global";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import { HalfCenteredRow } from "../../components/common/layout";
import { formatBodyRaw } from "../../utils/format-body-raw";
import { ContentfulPage } from "../../utils/contentful";

const BudgetInsightTermsOfServicePage = ({ data }) => {
  const budgetInsightTosPage: ContentfulPage =
    formatBudgetInsightTosQueryResult(data);
  return (
    <ThemeProvider theme={theme}>
      <Reset />
      <GlobalStyle />
      <main>
        <Helmet>
          <title>{getPageTitle(budgetInsightTosPage.title)}</title>
          <style type="text/css">{FontFaces}</style>
        </Helmet>
        <Header />
        <Wrapper>
          <HalfCenteredRow>
            <Heading className="heading">{budgetInsightTosPage.title}</Heading>
            {formatBodyRaw(budgetInsightTosPage.body.raw)}
          </HalfCenteredRow>
        </Wrapper>
        <Footer />
      </main>
    </ThemeProvider>
  );
};

export default BudgetInsightTermsOfServicePage;

export const budgetInsightTosQuery = graphql`
  query BudgetInsightTosQuery($language: String) {
    allContentfulPage(
      filter: {
        contentful_id: { in: ["1RVaF85E6DSVj2SqAal0bl"] }
        node_locale: { eq: $language }
      }
    ) {
      nodes {
        node_locale
        title
        body {
          raw
        }
      }
    }
  }
`;

interface BudgetInsightTosQueryResponse {
  allContentfulPage: {
    nodes: ContentfulPage[];
  };
}

function formatBudgetInsightTosQueryResult(
  queryResult: BudgetInsightTosQueryResponse
): ContentfulPage {
  return queryResult.allContentfulPage.nodes[0];
}

const Wrapper = styled.section`
  min-height: 100vh;
  box-sizing: border-box;
  padding: 6.25em 0;
`;

const Heading = styled.h1`
  text-align: center;
  margin-bottom: 1em;
`;
